$buttonColor: #90bd37;

a.hoowla-quote-button {
  display: inline;
  padding: 8px 12px;
  background-color: $buttonColor;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
  transition: 0.1s background ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    opacity: 0.8;
    margin-top: 1px;
    margin-left: 1px;
  }

  &.extra-large,
  &.large,
  &.small {
    display: inline-block;
  }

  &.extra-large {
    padding: 13px 20px;
    font-size: 19px;
  }

  &.large {
    padding: 11px 16px;
    font-size: 16px;
  }

  &.small {
    padding: 6px 8px;
    font-size: 11px;
  }
}

$popupHeight: 620px;
$popupWidth: 800px;

iframe.hoowla-quote-form {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  margin: auto;
  padding: 0;
  // haha, yes, all those nines really are necessary ;)
  z-index: 99999999999;
  text-align: left;
  background-clip: padding-box;
  background: white;
  font-size: 13px;
  color: #999;
}

.view-button {
  padding-right: 25px;
}

.align-center {
  text-align: center;
}

.will-content {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.will-content-small {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.will-button {
  padding: 8px 12px;
  background-color: $buttonColor;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
  transition: 0.1s background ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    opacity: 0.8;
  }

  &.extra-large,
  &.large,
  &.small {
    display: inline-block;
  }

  &.extra-large {
    padding: 13px 20px;
    font-size: 19px;
  }

  &.large {
    padding: 11px 16px;
    font-size: 16px;
  }

  &.small {
    padding: 6px 8px;
    font-size: 11px;
  }
}
.progress-wrapper {
  display: block;
}
.will_disclaimer small {
  padding: 0px !important;
}
.progress-value {
  line-height: 20px;
  color: #555;
  height: 18px;
  float: right;
  font-size: large;
}
progress {
  background-color: #ffffff;
  height: 18px;
  width: 80%;
  border-radius: 2px;
  border: 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress::-webkit-progress-bar {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 2px;
}
progress::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, #bfd255 0%, #72aa00 50%, #8eb92a 50%, #9ecb2d 64%), -webkit-linear-gradient(top, rgba(199, 218, 159, 0.25), rgba(0, 0, 0, 0.25));
  border-radius: 2px;
}
progress::-moz-progress-bar {
  background-image: -moz-linear-gradient(-45deg, #bfd255 0%, #72aa00 50%, #8eb92a 50%, #9ecb2d 64%), -moz-linear-gradient(top, rgba(199, 218, 159, 0.25), rgba(0, 0, 0, 0.25));
  border-radius: 2px;
}

@media only screen and (min-width: 701px) {
  // Desktop / Tablet

  iframe.hoowla-quote-form {
    position: fixed;
    height: $popupHeight;
    width: $popupWidth;
    border: 1px solid #999;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);

    &.with-custom-branches {
      height: $popupHeight + 35;
    }
  }

  div.hoowla-bg-shade {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.2);
    z-index: 99999998;
  }
}
@media only screen and (max-width: 700px) {
  // Mobile Only

  // Not great to change global css properties, but need to prevent a double scrollbar issue
  // in mobile view - which prevents an iOS/safari scroll.
  body {
    position: relative;
    margin: 0;
  }

  .float-left-50 {
    width: 50%;
    float: left;
  }
  .float-right-50 {
    width: 50%;
    float: right;
  }
  progress {
    width: 70%;
  }
  .will-content {
    min-height: 300px;
  }
}
