/* The following block can be included in a custom.scss */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* make the customizations */
$theme-colors: (
  "primary": #021e5e,
  "secondary": #ca971f
);

/* import bootstrap to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.body {
  font-family: "Poppins", serif;
  background-color: "transparent";
}

.container {
  max-width: 1200px;
}

nav {
  background-color: "transparent";
}
.nav-link {
  text-transform: uppercase;
  color: #021e5e;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.navbar-collapse {
  font-family: "Poppins", serif;
  font-size: 16px;

  .btn-secondary {
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 50px;
  }
  .btn-secondary:hover {
    background-color: #021e5e;
  }
}

.subheader {
  font-family: "Poppins", serif;
  background-color: rgba(202, 151, 31, 0.16);
  text-align: center;
  padding: 40px;

  h1 {
    padding: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 33px !important;
    color: #021e5e;
  }

  ol {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .breadcrumb {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    justify-content: center;
    font-size: 14px;

    .breadcrumb-item a {
      color: #707070 !important;
      text-decoration: none;
    }

    .breadcrumb-item a:hover {
      color: #ca971f !important;
      text-decoration: none;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      padding-right: 4px;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 4px;
    }
  }
}
