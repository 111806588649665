body {
  font-family: "Poppins", sans-serif;
}

.w-full {
  width: 100%;
}

.form-check-label {
  line-height: 1.4em;
  padding-left: 15px;
}

.small {
  font-size: 0.8em;
}

.italic {
  font-style: italic;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}

.p-inline-message {
  padding: 12px;
  margin: 0px;
}

.p-inline-message-text {
  padding-left: 10px;
}

.well {
  border-radius: 10px;
  border: 1px solid #dddddd;
  padding: 25px;
}

input[type="checkbox"] {
  transform: scale(2);
}

@media (min-width: 768px) {
  .form-horizontal .col-form-label {
    text-align: right;
  }
}

h5 {
  color: #ca971f;
}

.p-stepper .p-stepper-panels {
  padding: 0;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-title {
  color: #021e5e;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #ca971f;
  color: #ffffff;
}

.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
  background: #ca971f;
}

.p-stepper ul {
  margin-bottom: 0;
}
